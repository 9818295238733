import { useCallback } from 'react';
import { useMemo } from 'react';

import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { MetaTags, Pagination } from '@components';
import { usePagination, useSponsorsQuery } from '@hooks';
import { generateArray } from '@utils';
import { SponsorsIllustration } from '@assets';

import * as S from './styles';
import SponsorCard from './SponsorCard';

type HandlePagination = (selectedItem: { selected: number }) => void;

const Sponsors = () => {
  const history = useHistory();
  const { search } = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const currentPage = Number(queryParams.get('page') || '1');

  const sponsorsQuery = useSponsorsQuery(currentPage);
  const sponsors1 = sponsorsQuery.data?.sponsors.filter(
    (o) => o.type_partner === 1,
  );
  const sponsors2 = sponsorsQuery.data?.sponsors.filter(
    (o) => o.type_partner === 2,
  );
  const sponsors3 = sponsorsQuery.data?.sponsors.filter(
    (o) => o.type_partner === 3,
  );
  const { t } = useTranslation();

  const pagination = usePagination(
    sponsorsQuery.data?.meta.pagination.total_pages,
  );

  const handlePageNavigation = useCallback<HandlePagination>(
    ({ selected }) => {
      queryParams.set('page', String(selected + 1));

      history.push({
        search: `?${queryParams.toString()}`,
      });
    },
    [history, queryParams],
  );

  return (
    <S.Container>
      <MetaTags title={t('pages.sponsors.title')} />

      <S.SponsorsHeader>
        <div>
          <span>{t('pages.sponsors.subTitle')}</span>
          <h1>{t('pages.sponsors.title')}</h1>
        </div>

        <SponsorsIllustration />
      </S.SponsorsHeader>

      {!!sponsors1?.length && (
        <div>
          <h3>{t('pages.sponsors.title1')}</h3>
        </div>
      )}

      <S.ListWrapper>
        {sponsorsQuery.isLoading
          ? generateArray(10).map((key) => (
              <SponsorCard key={`sponsor-${key}`} loading />
            ))
          : sponsors1?.map((sponsor) => (
              <SponsorCard key={`sponsor-${sponsor.id}`} data={sponsor} />
            ))}
      </S.ListWrapper>
      {!!sponsors2?.length && (
        <div>
          <h3>{t('pages.sponsors.title2')}</h3>
        </div>
      )}
      <S.ListWrapper>
        {sponsorsQuery.isLoading
          ? generateArray(10).map((key) => (
              <SponsorCard key={`sponsor-${key}`} loading />
            ))
          : sponsors2?.map((sponsor) => (
              <SponsorCard key={`sponsor-${sponsor.id}`} data={sponsor} />
            ))}
      </S.ListWrapper>
      {!!sponsors3?.length && (
        <div>
          <h3>{t('pages.sponsors.title3')}</h3>
        </div>
      )}
      <S.ListWrapper>
        {sponsorsQuery.isLoading
          ? generateArray(10).map((key) => (
              <SponsorCard key={`sponsor-${key}`} loading />
            ))
          : sponsors3?.map((sponsor) => (
              <SponsorCard key={`sponsor-${sponsor.id}`} data={sponsor} />
            ))}
      </S.ListWrapper>
      <Pagination
        pageCount={pagination}
        forcePage={currentPage}
        onPageChange={handlePageNavigation}
      />
    </S.Container>
  );
};

export default Sponsors;
