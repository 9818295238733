import { memo } from 'react';

import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

import { useEventfy, useSponsorsQuery } from '@hooks';
import { ONE_MINUTE } from '@constants';
import { selectHasSponsors } from '@hooks/useEventfy/selectors';

import * as S from './styles';
import { CAROUSEL_BREAKPOINTS } from './constants';

export type SponsorsCarouselProps = {
  size?: 'default' | 'small';
  typePartner?: 1 | 2 | 3;
};

const SponsorsCarousel = ({
  size = 'default',
  typePartner = 1,
}: SponsorsCarouselProps) => {
  const eventHasSponsors = useEventfy(selectHasSponsors);

  const { isLoading, isSuccess, data } = useSponsorsQuery();
  const { t } = useTranslation();

  const isDefaultSize = size === 'default';
  const isSponsorSilver = typePartner === 1;
  if (!eventHasSponsors || isLoading || !data?.sponsors.length) {
    return null;
  }
  const sponsors = data.sponsors.filter((o) => o.type_partner === typePartner);

  return (
    <S.Container $defaultSize={isDefaultSize} $typePartner={isSponsorSilver}>
      {!!sponsors?.length && (
        <>
          <h3>{t(`pages.sponsors.title${typePartner}`)}</h3>
          <Slider
            infinite={sponsors?.length > 4}
            arrows={false}
            autoplay
            autoplaySpeed={isDefaultSize ? 3500 : ONE_MINUTE / 2}
            swipe
            accessibility
            pauseOnHover
            className="sponsors-carousel"
            dots={isSuccess}
            slidesToShow={isDefaultSize ? 5 : 3}
            slidesToScroll={isDefaultSize ? 3 : 2}
            responsive={
              isDefaultSize ? CAROUSEL_BREAKPOINTS : [CAROUSEL_BREAKPOINTS[2]]
            }
          >
            {sponsors.map((sponsor) => (
              <div key={`sponsor-${sponsor.id}`}>
                <Link to={`/sponsors/${sponsor.id}`}>
                  <img src={sponsor.logo} alt={sponsor.name} loading="lazy" />
                </Link>
              </div>
            ))}
          </Slider>
        </>
      )}
    </S.Container>
  );
};

export default memo(SponsorsCarousel);
