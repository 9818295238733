import {
  FiCast,
  FiChevronRight,
  FiChevronDown,
  FiClock,
  FiLayers,
  FiUsers,
} from 'react-icons/fi';
import { FaPlay } from 'react-icons/fa';
import { FaDotCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

import { MetaTags, ScheduleSession, SectionHeader, Speaker } from '@components';
import { useEventfy, useLobbyQueries } from '@hooks';
import { formatRoomDisplayData, generateArray } from '@utils';
import { SponsorsCarousel } from '@components';
import { VideosCarousel } from '@components';

import EventDescription from './EventDescription';
import NewsFeedPreview from './NewsFeedPreview';
import GamificationRanking from './GamificationRanking';
import * as S from './styles';

const Lobby = () => {
  const event = useEventfy();

  const queries = useLobbyQueries();
  const [roomsQuery, speakersQuery, scheduleQuery] = queries;
  const { t } = useTranslation();

  return (
    <S.Container>
      <MetaTags title="Lobby" />

      <img src={event.banner} alt={event.name} loading="lazy" />

      <S.Section>
        <SectionHeader
          title={`${t('pages.lobby.about')} ${event.name}`}
          icon={FiLayers}
        />

        <S.DescriptionWrapper>
          <S.DescriptionCollapser>
            <EventDescription description={event.description} />
          </S.DescriptionCollapser>

          <S.MainRoomContainer
            $loading={!roomsQuery.isSuccess}
            to={`/broadcast/${roomsQuery.data?.rooms[0].slug}`}
          >
            <S.MainRoomPlayIcon>
              <FaPlay size="1.5rem" />
            </S.MainRoomPlayIcon>

            {!roomsQuery.isSuccess ? (
              <Skeleton width="22rem" height="12.375rem" />
            ) : (
              <img
                src={roomsQuery.data.rooms[0].thumb_image}
                alt={roomsQuery.data.rooms[0].name}
              />
            )}

            <S.MainRoomBadge>
              <span>
                <FiCast />
              </span>

              <strong>{t('pages.lobby.mainRoom')}</strong>
              <FiChevronRight />
            </S.MainRoomBadge>
          </S.MainRoomContainer>
        </S.DescriptionWrapper>
      </S.Section>

      {event.rooms > 1 && (
        <S.Section>
          <SectionHeader title={t('pages.lobby.rooms')} icon={FiLayers}>
            {roomsQuery.data?.rooms && roomsQuery.data?.rooms.length > 3 && (
              <Link to="rooms">{t('pages.lobby.showMoreRooms')}</Link>
            )}
          </SectionHeader>

          <S.RoomCardsContainer>
            {roomsQuery.data?.rooms.slice(1, 4).map((room) => {
              const data = formatRoomDisplayData(room);
              return (
                <S.RoomCard key={room.slug}>
                  <Link to={`/broadcast/${room.slug}`}>
                    <img src={room.thumb_image} alt={room.name} />
                    <S.RoomCardDataWrapper>
                      <S.RoomCardDate>
                        <strong>{data.day}</strong>
                        <span>{t(`date.month.${data.month}`)}</span>
                      </S.RoomCardDate>

                      <S.RoomCardInfo $online={data.isOnline}>
                        <strong>{room.name}</strong>
                        <span>
                          <FaDotCircle size="0.75rem" /> {t(data.status)}
                        </span>
                      </S.RoomCardInfo>
                    </S.RoomCardDataWrapper>
                  </Link>
                </S.RoomCard>
              );
            })}
          </S.RoomCardsContainer>
        </S.Section>
      )}

      <VideosCarousel />

      <NewsFeedPreview />

      <GamificationRanking />

      <S.FlexWrapper>
        <section>
          <SectionHeader title={t('pages.lobby.speakers')} icon={FiUsers} />
          <ul>
            {!speakersQuery.isSuccess ? (
              generateArray(3).map((key) => <Speaker key={key} loading />)
            ) : (
              <>
                {speakersQuery.data.speakers.slice(0, 4).map((speaker) => (
                  <Speaker key={`speaker-${speaker.id}`} data={speaker} />
                ))}

                {speakersQuery.data.speakers.length > 4 && (
                  <S.ViewMore to="/speakers">
                    <span>
                      <FiChevronDown size="1.5rem" />
                    </span>
                  </S.ViewMore>
                )}
              </>
            )}
          </ul>
        </section>

        <section>
          <SectionHeader title={t('pages.lobby.schedule')} icon={FiClock} />

          <S.ScheduleContainer>
            {!scheduleQuery.isSuccess ? (
              generateArray(2).map((key) => (
                <ScheduleSession key={key} loading />
              ))
            ) : (
              <>
                {scheduleQuery.data.schedule.slice(0, 3).map((session) => (
                  <ScheduleSession
                    key={`session-${session.id}`}
                    data={session}
                  />
                ))}

                {scheduleQuery.data.schedule.length > 3 && (
                  <S.ViewMore to="/schedule" $big>
                    <span>
                      <FiChevronDown size="1.5rem" />
                    </span>
                  </S.ViewMore>
                )}
              </>
            )}
          </S.ScheduleContainer>
        </section>
      </S.FlexWrapper>

      <SponsorsCarousel typePartner={1} />
      <SponsorsCarousel typePartner={2} />
      <SponsorsCarousel typePartner={3} />
    </S.Container>
  );
};

export default Lobby;
