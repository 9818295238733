import { useState } from 'react';
import { useEffect } from 'react';

import { FiCast } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import { MetaTags, SectionHeader } from '@components';
import {
  useBroadcastQuery,
  useBroadcastChannels,
  useFloatingPlayer,
} from '@hooks';
import { StreamingOrigin } from '@common/types/api';
import { SponsorsCarouselAll } from '@components';

import Interactions from './Interactions';
import Streaming from './Streaming';
import RoomInformation from './RoomInformation';
import SelectPlayerLanguage from './SelectPlayerLanguage';
import CallToAction from './CallToAction';
import * as S from './styles';

const Broadcast = () => {
  const broadcastQuery = useBroadcastQuery();
  const { t } = useTranslation();
  const { isVisible: isFloatingPlayerVisible, updateFloatingPlayer } =
    useFloatingPlayer();

  const [currentLanguageIndex, setCurrentLanguageIndex] = useState(0);
  const isZoom =
    StreamingOrigin.ZOOM ===
    broadcastQuery.data?.streamings.data[currentLanguageIndex].streaming_method;
  useBroadcastChannels(broadcastQuery.data?.id);

  useEffect(() => {
    if (isFloatingPlayerVisible) {
      updateFloatingPlayer({ isVisible: false });
    }
  }, [isFloatingPlayerVisible, updateFloatingPlayer]);

  useEffect(() => {
    const src =
      broadcastQuery.data?.streamings.data[currentLanguageIndex].url_video;
    const roomName = broadcastQuery.data?.name;
    const streamingMethod =
      broadcastQuery.data?.streamings.data[currentLanguageIndex]
        .streaming_method;

    return () => {
      if (src && roomName) {
        updateFloatingPlayer({
          src,
          roomName,
          streamingMethod,
          isVisible: true,
        });
      }
    };
  }, [
    broadcastQuery.data?.name,
    broadcastQuery.data?.streamings.data,
    currentLanguageIndex,
    updateFloatingPlayer,
  ]);

  return (
    <S.Container $zoom={isZoom}>
      <MetaTags
        title={
          broadcastQuery.data?.name
            ? `${t('pages.broadcast.title')} "${broadcastQuery.data.name}"`
            : t('pages.broadcast.titleFall')
        }
      />

      <S.BroadcastHeader>
        <SectionHeader
          as="div"
          icon={FiCast}
          title={broadcastQuery.data?.name}
        />

        {broadcastQuery.data &&
          broadcastQuery.data.streamings.data.length > 1 && (
            <SelectPlayerLanguage
              currentIndex={currentLanguageIndex}
              setCurrentIndex={setCurrentLanguageIndex}
            />
          )}

        <CallToAction />
      </S.BroadcastHeader>

      {broadcastQuery.data?.streamings.data[currentLanguageIndex]
        .streaming_method === StreamingOrigin.ZOOM ? (
        <>
          <S.ContentGridZoom>
            <Streaming
              data={broadcastQuery.data?.streamings.data[currentLanguageIndex]}
              thumb={broadcastQuery.data?.thumb_image}
            />
            <Interactions />
          </S.ContentGridZoom>

          {/* <S.Interactions
            className={isVisibleInteractions ? 'interactionsInvisible' : ''}
          >
            <Interactions toggleVisibility={() => setIsVisibleInteractions()} />
          </S.Interactions> */}
        </>
      ) : (
        <S.ContentGrid>
          <Streaming
            data={broadcastQuery.data?.streamings.data[currentLanguageIndex]}
            thumb={broadcastQuery.data?.thumb_image}
          />

          <Interactions />
          <SponsorsCarouselAll size="small" />
          <RoomInformation />
        </S.ContentGrid>
      )}
    </S.Container>
  );
};

export default Broadcast;
